import { Accordion } from 'bootstrap'
import 'unpoly'
import hyperform from 'hyperform'
import confetti from 'canvas-confetti'

function debounce(func){
    let timer;
    return function(event){
        if(timer) clearTimeout(timer);
        timer = setTimeout(func,100,event);
    };
}

function vhFix() {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

up.compiler('body', () => {
    window.installPrompt = null
    const pwaPrompt = document.getElementById('js-pwa-install');

    // Detects if device is on iOS
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    // Try to log pwa-installs
    window.addEventListener('beforeinstallprompt', e => {
        e.userChoice.then(choiceResult => {
            if (window.gtag !== undefined) {
                gtag('event', 'PwaInstall', {
                    'outcome': choiceResult.outcome
                });
            }

            if (pwaPrompt) {
                pwaPrompt.classList.add('d-none');
            }
        });

        window.installPrompt = e;

        if (pwaPrompt) {
            pwaPrompt.classList.remove('d-none');
        }
    });

    window.addEventListener('appinstalled', () => {
        // Hide the app-provided install promotion
        pwaPrompt.classList.add('d-none')
        // Clear the deferredPrompt so it can be garbage collected
        window.installPrompt = null
    });

    if (pwaPrompt) {
        pwaPrompt.addEventListener('click', async (e) => {
            e.preventDefault()

            pwaPrompt.classList.add('d-none')
            window.installPrompt.prompt();
            const { outcome } = await window.installPrompt.userChoice;

            window.installPrompt = null;
        })

        // Checks if should display install popup notification:
        // if (isIos() && !isInStandaloneMode()) {
        //     pwaPrompt.classList.remove('d-none')
        // }
    }
})

up.compiler('#js-main', () => {
    // We listen to the resize event
    window.addEventListener('resize', debounce(vhFix));
    setTimeout(() => {
        vhFix()
    }, 500)

    return () => {
        window.removeEventListener('resize', debounce(vhFix))
    }
})

up.compiler('form', (element) => {
    hyperform.addTranslation("de", {
        TextTooLong: "Bitte kürzen Sie diesen Text auf maximal %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        TextTooShort: "Bitte verwenden Sie zumindest %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        ValueMissing: "Bitte füllen Sie dieses Feld aus.",
        CheckboxMissing: "Bitte klicken Sie dieses Kästchen an, wenn Sie fortsetzen wollen.",
        RadioMissing: "Bitte wählen Sie eine dieser Optionen.",
        FileMissing: "Bitte wählen Sie eine Datei.",
        SelectMissing: "Bitte wählen Sie einen Eintrag in der Liste.",
        InvalidEmail: "Bitte geben Sie eine E-Mail-Adresse ein.",
        InvalidURL: "Bitte geben Sie eine Internetadresse ein.",
        PatternMismatch: "Bitte halten Sie sich an das vorgegebene Format.",
        PatternMismatchWithTitle: "Bitte halten Sie sich an das vorgegebene Format: %l.",
        NumberRangeOverflow: "Bitte wählen Sie einen Wert, der nicht größer ist als %l.",
        DateRangeOverflow: "Bitte wählen Sie einen Wert, der nicht später ist als %l.",
        TimeRangeOverflow: "Bitte wählen Sie einen Wert, der nicht später ist als %l.",
        NumberRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht kleiner ist als %l.",
        DateRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
        TimeRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
        StepMismatch: "Bitte wählen Sie einen gültigen Wert. Die zwei nähesten gültigen Werte sind %l und %l.",
        StepMismatchOneValue: "Bitte wählen Sie einen gültigen Wert. Der näheste gültige Wert ist %l.",
        BadInputNumber: "Bitte geben Sie eine Nummer ein.",
        "Please match the requested type.": "Bitte passen Sie die Eingabe dem geforderten Typ an.",
        "Please comply with all requirements.": "Bitte erfüllen Sie alle Anforderungen.",
        "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Bitte verlängern Sie diesen Text auf mindestens %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        "Please use the appropriate format.": "Bitte verwenden Sie das passende Format.",
        "Please enter a comma separated list of email addresses.": "Bitte geben Sie eine komma-getrennte Liste von E-Mail-Adressen an.",
        "Please select a file of the correct type.": "Bitte wählen Sie eine Datei vom korrekten Typ.",
        "Please select one or more files.": "Bitte wählen Sie eine oder mehrere Dateien.",
        "any value": "jeder Wert",
        "any valid value": "jeder gültige Wert",
    });
    hyperform.setLanguage("de");
    hyperform(window);
})

up.compiler('[data-vibrate]', (element) => {
    const duration = element.dataset.vibrate || 500;

    window && window.navigator && window.navigator.vibrate && window.navigator.vibrate(duration);
})

up.compiler('[data-confetti]', () => {
    confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
    });
})
