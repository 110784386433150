import {MapboxController} from "./mapbox_controller";
import mapboxgl from '!mapbox-gl';
import Cookies from 'js-cookie';

export default class extends MapboxController {
    static targets = ['options']
    static values = {routes: String, sponsors: String, style: String, showSponsors: Boolean, showRoutes: Boolean}
    routeMarkers = []
    sponsorMarkers = []

    // Optional
    defaultConfig() {
        return {
            accessToken: 'pk.eyJ1Ijoic2FobGktaW50ZXJhY3RpdmUiLCJhIjoiY2ttMXY4YzNzMXg0aDJ1cnpoZGZjdXJ1MSJ9.CBdg5yGt0KiCtDCav_47Xg',
            style: Cookies.get('map.view') || 'streets-v11',
            center: [7.561, 46.87298],
            zoom: Cookies.get('map.zoom') || 13,
            locale: 'de',
            controls: {
                "geolocate": {
                    positionOptions: {
                        enableHighAccuracy: true
                    },
                    trackUserLocation: true,
                    position: 'bottom-right'
                }
            }
        }
    }

    getStyleUrl(style) {
        return "mapbox://styles/mapbox/" + style;
    }

    // Initialize markers
    onMapLoaded(map) {
        this.showRoutesValue = Cookies.get('map.routes') === '1' || !Cookies.get('map.routes')
        this.showSponsorsValue = Cookies.get('map.sponsors') === '1'

        let routes = JSON.parse(this.routesValue)
        let sponsors = JSON.parse(this.sponsorsValue)

        if (routes.data) {
            routes.data.features.forEach((marker) => {
                let el = document.createElement('div')
                el.className = marker.properties.icon
                el.innerHTML = marker.properties.icon_text

                let popup = new mapboxgl.Popup({offset: 25})
                    .setHTML(marker.properties.description)

                let mapMarker = new mapboxgl.Marker(el);


                mapMarker.setLngLat(marker.geometry.coordinates)
                    .setPopup(popup)
                    .addTo(map)

                this.routeMarkers.push(mapMarker);

                if (marker.properties.current) {
                    map.flyTo({
                        center: marker.geometry.coordinates
                    });
                }
            });

            this.drawRoutes(routes)
        }

        if (!this.showRoutesValue) this.hideRoutes()

        if (sponsors.data) {
            sponsors.data.features.forEach((marker) => {
                let el = document.createElement('div')
                el.className = marker.properties.icon
                el.innerHTML = marker.properties.icon_text

                let popup = new mapboxgl.Popup({offset: 25})
                    .setHTML(marker.properties.description)

                let mapMarker = new mapboxgl.Marker(el);


                mapMarker.setLngLat(marker.geometry.coordinates)
                    .setPopup(popup)
                    .addTo(map)

                this.sponsorMarkers.push(mapMarker);

                if (marker.properties.current) {
                    this.showSponsorsValue = true;

                    map.flyTo({
                        center: marker.geometry.coordinates
                    });
                }
            });
        }

        if (!this.showSponsorsValue) this.hideSponsors()

        map.on('zoom', () => {
            Cookies.set('map.zoom', map.getZoom());
        })

        map.on('style.load', () => {
            // Triggered when `setStyle` is called.
            this.drawRoutes(routes);
        });
    }

    changeStyle(style) {
        Cookies.set('map.view', style);
        this.styleValue = style
        this.__map.setStyle(this.getStyleUrl(style))
    }

    setStyleSatellite() {
        this.changeStyle('satellite-streets-v11')
    }

    setStyleStreets() {
        this.changeStyle('streets-v11')
    }

    toggleRoutes() {
        if (this.showRoutesValue) {
            this.hideRoutes()
        } else {
            this.showRoutes()
        }

        this.showRoutesValue = !this.showRoutesValue
        Cookies.set('map.routes', this.showRoutesValue ? '1' : '0');
    }

    showRoutes() {
        this.routeMarkers.forEach((item) => {
            item._element.style.visibility = 'visible'
        })
    }

    hideRoutes() {
        this.routeMarkers.forEach((item) => {
            item._element.style.visibility = 'hidden'
        })
    }

    toggleSponsors() {
        if (this.showSponsorsValue) {
            this.hideSponsors()
        } else {
            this.showSponsors()
        }

        this.showSponsorsValue = !this.showSponsorsValue
        Cookies.set('map.sponsors', this.showSponsorsValue ? '1' : '0');
    }

    hideSponsors() {
        this.sponsorMarkers.forEach((item) => {
            item._element.style.visibility = 'hidden'
        })
    }

    showSponsors() {
        this.sponsorMarkers.forEach((item) => {
            item._element.style.visibility = 'visible'
        })
    }

    toggleOptions() {
        this.optionsTarget.classList.toggle('-visible')
    }

    addDirections(coordinates, routeName, color) {
        let url = 'https://api.mapbox.com/directions/v5/mapbox/cycling/' + coordinates + '?geometries=geojson&steps=true&access_token=' + this.__mapConfig.accessToken;
        let req = new XMLHttpRequest();
        req.responseType = 'json';
        req.open('GET', url, true);
        req.onload = () => {
            let jsonResponse = req.response;
            // add results to info box
            let coords = jsonResponse.routes[0].geometry;
            // add the route to the map
            this.drawRoute(coords, routeName, color);
        };
        req.send();
    }

    ensureRouteDrawingsAreVisible(routes) {
        routes.data.drawing.forEach((_value, index) => {
            this.__map.setLayoutProperty(`route${index}`,'visibility','visible');
        })
    }

    drawRoutes(routes) {
        if (routes.data && routes.data.drawing) {
            routes.data.drawing.forEach((value, index) => {
                this.drawRoute(value, index, routes.data.colors[index])
            })
        }
    }

    drawRoute(coords, routeName, color) {
        this.__map.addLayer({
            "id": `route${routeName}`,
            "type": "line",
            "source": {
                "type": "geojson",
                "data": {
                    "type": "Feature",
                    "properties": {},
                    "geometry": coords
                }
            },
            "layout": {
                "line-join": "round",
                "line-cap": "round"
            },
            "paint": {
                "line-color": color,
                "line-width": 8,
                "line-opacity": 0.8
            }
        });
    }

    // Cleanup if needed
    onMapUnloaded(map) {
    }

}
