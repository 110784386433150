import QrScanner from 'qr-scanner';
import { Controller } from "stimulus";
QrScanner.WORKER_PATH = '/qr-scanner-worker.min.js';

export default class extends Controller {
    static scanner;

    connect() {
        this.scanner = new QrScanner(this.element, result => {
            if (this.isValidUrl(result)) {
                window.location.href = result;
            }
        });

        if (QrScanner.hasCamera()) {
            this.scanner.start()
        }
    }

    isValidUrl(str) {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str) && str.startsWith(`${location.protocol}//${location.hostname}`);
    }

    disconnect() {
        this.scanner.stop()
        this.scanner.destroy()
    }
}
